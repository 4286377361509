import(/* webpackMode: "eager" */ "/vercel/path0/apps/aura/public/assets/backgrounds/wave.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/aura/public/assets/marketing/aura/profile-wheel.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.12_react@18.3.1__@emotion+style_5fmx4zgukdrr7c4hrz6h7clcfi/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.12_react@18.3.1__@emotion+style_5fmx4zgukdrr7c4hrz6h7clcfi/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_4dz3lwytrc6enqxc3gss4umtra/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["I18nClientProvider"] */ "/vercel/path0/packages/shared/i18n/src/provider/i18n-client.provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/components/button-link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/components/link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/components/locale-switcher.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/router-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/use-decode-pathname-with-query-params.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/use-decode-pathname.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared/router/src/hooks/use-router.ts");
